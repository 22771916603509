.button {
    background: #158444;
    font-size: 1rem;
    line-height: 2.1;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    border-radius: 4px;
    background-clip: padding-box;
    box-sizing: content-box;
    padding: 0 12px;
    margin: 0 0 0 10px;
    display: inline-flex;
    transition: all 0.25s ease-in-out;
    user-select: none;
    border: none;
    word-spacing: normal;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.button.hasIcon {
    padding: 0 14px 0 10px;
}

.button.rightMargin {
    margin: 0 10px 0 0;
}

.button .icon {
    margin: 0 4px 0 0;
    height: 20px;
}

.button:not(.disabled):hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 2px 0 inset, rgba(0, 0, 0, 0.1) 0 0 0 100px inset !important;
}

.button.small {
    padding: 6px 11px;
    font-size: 0.86rem;
    border-radius: 3px;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.button-bg-blue {
    background: #006AF5;
    color: #ffffff;
}
.button-bg-green {
    background: rgba(22, 136, 70, 0.5);
}
.button-green {
    background: #f3f5f7;
    color: #0A7637;
}
.button-bg-gray {
    background: rgba(28, 48, 74, 0.52);
    color: #0A7637;
}
.button-green-solid {
    background: rgb(22, 136, 69);
    color: #fff;
}

.button-bg-red {
    background: rgb(198 32 32);
    color: #fff;
}
.button-white {
    background: #ffffff;
    color: #000;
}
.button-blue {
    background: #f3f5f7;
    color: #005FDB;
}
.button-gray {
    background: #f3f5f7;
    color: rgba(28, 48, 74, 0.52);
}
.button-purple {
    background: #f3f5f7;
    color: #650ACC;
}
.button-cyan {
    background: #cdeef1;
    color: #650ACC;
}
.button-warning {
    background: #dd8524;
}
.button-error {
    background: #d9534f;
}
.button-success {
    background: #5cb85c;
}
.button-info {
    background: #079ce2;
}
.button-red {
    background: #f3f5f7;
    color: rgba(202, 21, 12, 1);
}
.button-none {
    color: #797979;
    text-decoration: underline;
    background: transparent;
}
.button-link {
    color: #005FDB;
    background: transparent;
    margin: 0;
    padding: 0;
    text-decoration:none;
}
.button.button-link:hover,
.button-none:not(.disabled):hover {
    box-shadow: none !important;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.button .icon.iconSpin:before {
    animation: spin 1s infinite linear;
    display: inline-block;
}
