@value clrBorder: rgba(26, 56, 96, 0.1);
@value edgePadding: 20px;
@value topPadding: 4px;
@value topHeaderPadding: 10px;
@value searchLoaderSize: 20px;
@value headerHeight: 25px;

.datagrid {
    width: 100%;
}

.datagridLoading {
    height: 4.286rem;
    font-size: 4.286rem;
    min-height: inherit;
}

.datagrid table {
    border-left: 1px solid clrBorder;
    border-top: 1px solid clrBorder;
    width: 100%;
    table-layout: auto;
    margin-bottom: 20px;
    font-size: 0.93rem;
    border-collapse: collapse;
}

.datagrid table tr {
    border: 1px solid clrBorder;
}

.datagrid table td,
.datagrid table th {
    border-left: 0;
    border-right: 1px solid clrBorder;
    padding: 8px 10px;
    height: 20px;
    vertical-align: middle;
    text-align: left;
}

.datagrid table tr td button {
    font-size: 0.93rem;
    font-weight: normal;
}

.datagrid table th {
    color: rgba(27, 43, 65, 0.69);
    font-size: 0.87rem;
    height: 3rem;
    font-weight: 500;
}

.datagrid table td {
    color: rgba(25, 39, 57, 0.94);
}

.datagridHeader .col.colSortable {
    cursor: pointer;
}

.datagridHeader .col .sortIndicator {
    display: inline-block;
    position: absolute;
}

.datagridHeader .col .sortIndicator svg {
    margin: 0.1rem 0.5rem;
}
.datagridHeader .col .sortIndicator.sorted svg {
    margin: 0.35rem 0.5rem;
}

.datagrid tbody.body {
    width: 100%;
}

.datagrid tbody.body tr td {
    border-bottom: 1px solid clrBorder;
}

.datagrid tbody.body tr .col {
    vertical-align: middle;
}

.colActions {
    width: 1px;
    white-space: nowrap;
}

.actionButton {
    margin-left: 7px;
}

.actionButton:first-child {
    margin-left: 0;
}

.batchCol {
    width: 15px;
}

td.batchCol {
    cursor: pointer;
}

.batchActions {
    display: flex;
    align-items: center;
    padding: 0 0 1rem 0;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: baseline;
}
