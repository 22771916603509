.root {
    background: rgba(25, 59, 103, 0.05);
    overflow: hidden;
    box-sizing: border-box;
    overflow-x: auto;
}

.border {
    border-bottom: 1px solid rgba(26, 56, 96, 0.1);
}

.tabs {
    padding: 0.6rem 1.5rem;
    margin: 0 0 -1px 0;
    white-space: nowrap;
    display: flex;
}

.tab {
    display: inline-block;
    margin: 0 5px 0 0;
    position: relative;
    z-index: 4;
    user-select: none;
}

.link {
    display: block;
    padding: 0 1.5rem 0 0;
    color: gray;
    text-decoration: none;
    float: left;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
}

.active {
    color: #005FDB !important;
}

.active:after {
    bottom: -0.7rem;
    left: 50%;
    border: 2px solid #006AF5;
    content: " ";
    height: 0;
    width: 2rem;
    border-radius: 2px 2px 0 0;
    position: absolute;
    pointer-events: none;
    margin-left: -1.5rem;
}

.right {
    float: right;
    margin-top: 5px;
}
