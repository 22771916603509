.contentHeader {
    background: rgba(25, 59, 103, 0.05);
    padding: 0.6rem 1.5rem;
    margin: 0;
}

.title {
    display: block;
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0;
}

.subtitle {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0 0 2rem 0;
    text-align: center;
}

.header {
    display: flex;
    flex-flow: row;
    align-content: space-between;
    padding: 1.5rem 1rem 0 1.5rem;
    justify-content: space-between;
    align-items: flex-start;
}

.search {
    display: flex;
    width: 100%;
}

.filterPanel {
    width: 100%;
}

.filters {
    margin-left: 10px;
}

.toggle {
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    margin-bottom: -0.5rem;
    width: 300px;
}

.toggleLabel {
    align-self: center;
}

.actions {
    float: right;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
}

.topContent {
    /* padding: 1.5rem 1.5rem 0 1.5rem; */
}

.content {
    padding: 1.5rem;
}

.bottomContent {
    padding: 0 1.5rem 1.5rem 1.5rem;
}

.text {
    font-size: 1rem;
    line-height: 2;
    margin-top: -15px;
    margin-bottom: 40px;
}

.breadcrumbs {
    background: rgba(25, 59, 103, 0.05);
    padding: 0.6rem 1.5rem;
    margin: 0;
    overflow: hidden;
    list-style: none;
    border-bottom: 1px solid rgba(26, 56, 96, 0.1);
}

.breadcrumbs li {
    float: left;
    font-size: 0.86rem;
}

.breadcrumbs li:not(:first-child):before {
    content: ' ';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 0 7px 7px;
    border-color: transparent transparent transparent rgba(28, 46, 69, 0.6);
    line-height: 0;
    float: left;
    display: block;
    margin: 10px 10px 0 0;
}

.breadcrumbs li button,
.breadcrumbs li a {
    background: rgba(25, 59, 103, 0.05);
    color: rgba(27, 43, 65, 0.69);
}

.breadcrumbs li span {
    display: inline-block;
    background: rgba(25, 59, 103, 0.05);
    color: #005FDB;
    font-size: 1rem;
    line-height: 2.1;
    border-radius: 4px;
    padding: 0 12px;
}
