@value size: 15px;
@value sizeBig: 26px;

.checkBox {
    display: inline-block;
    width: size;
    height: size;
    background: #23323b;
    border-radius: 2px;
    vertical-align: middle;
    text-align: center;
    flex: 0 0 15px;
    margin-top: 2px;
}

.checkBox .icon {
    visibility: hidden;
    display: block;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 0.76rem;
    color: #fff;
    line-height: size;
}

.checked .icon {
    background: #006AF5;
    visibility: visible;
    opacity: 1;
}

.disabled {
    opacity: 0.5;
}

.size-big {
    width: sizeBig;
    height: sizeBig;
    border-radius: 3px;
}

.size-big .icon {
    line-height: sizeBig;
    font-size: 1rem;
}

.checkBox.dark {
    background: #23323b;
}

.color-client {
    background: #fff;
    border: 1px solid #ccc;
    transition: all 0.15s;
    border-radius: 0;
}

.checkBoxRow {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    user-select: none;
}

.checkBoxRow .checkBox {
    margin-right: 8px;
}
