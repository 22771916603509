.pagination {
    clear: both;
    overflow: hidden;
    padding-bottom: 20px;
    user-select: none;
    text-align: right;
}

.pagination .page {
    padding: 0 7px;
    font-size: 0.85rem;
    color: #6b778c;
    background: #f4f5f7;
    border: #ebecf0 1px solid;
    font-weight: bold;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background-clip: padding-box;
    margin: 2px;
    min-height: 22px;
    line-height: 22px;
}

.pagination .page.active {
    background-color: #c1c4d2;
}
